import type { NitroFetchOptions } from 'nitropack'
import defu from 'defu'
import * as Sentry from '@sentry/nuxt'
import { useRouter } from '#app'
import { useAuthStore } from '~/stores/auth'

type FetchOptions = NitroFetchOptions<any>

export default function (): any {
  const router = useRouter()
  const { accessToken } = useAuthStore()
  const config = useRuntimeConfig()
  const toast = useToast()

  const makeOptions = (opts?: FetchOptions): FetchOptions => ({
    ...opts,
    baseURL: `${config.public.apiUrl}`,
    onResponseError: ({ response }) => {
      if (response.status === 401) {
        router.push('/login')
      }
      if (response.status === 500) {
        if (import.meta.client) {
          toast.add({
            id: 'api_error',
            color: 'red',
            title: 'Une erreur est survenue',
            description: response._data.message,
            icon: 'i-heroicons-exclamation-triangle',
            timeout: 0
          })
        }
        Sentry.captureException(response)
      }
    },
    headers: {
      ...(opts?.headers || {}),
      'Authorization': accessToken ? `Bearer ${accessToken}` : undefined,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })

  const executeFetch = async (url: string, options: FetchOptions) => {
    const params = defu(options)

    // const data: any = await $fetch(url, options)
    const { data, error, status }: any = await useFetch(url, params)

    if (error.value || status.value == 'error') {
      throw error.value
    }
    return data.value
  }

  const executeDownload = async (url: string, options: FetchOptions, fileName: string) => {
    const params = defu(options, { responseType: 'blob' }) // Attend une réponse sous forme de fichier
    const { data, error, status }: any = await useFetch(url, params)

    if (error.value || status.value == 'error') {
      throw error.value
    }

    const blob = data.value as Blob
    const contentDisposition = error.response?.headers?.get('content-disposition')
    let filename = fileName

    if (contentDisposition) {
      const matches = /filename="(.+)"/.exec(contentDisposition)
      if (matches != null && matches[1]) {
        filename = matches[1]
      }
    }

    const urlBlob = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = urlBlob
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.remove()

    window.URL.revokeObjectURL(urlBlob)
  }

  return {
    get: (url: string, options?: FetchOptions) => executeFetch(url, makeOptions(options)),
    post: (url: string, data: any, options?: FetchOptions) =>
      executeFetch(url, {
        method: 'POST',
        body: data,
        ...makeOptions(options)
      }),
    put: (url: string, data: any, options?: FetchOptions) =>
      executeFetch(url, {
        method: 'PUT',
        body: data,
        ...makeOptions(options)
      }),
    patch: (url: string, data: any, options?: FetchOptions) =>
      executeFetch(url, {
        method: 'PATCH',
        body: data,
        ...makeOptions(options)
      }),
    delete: (url: string, data: any, options?: FetchOptions) =>
      executeFetch(url, {
        method: 'DELETE',
        body: data,
        ...makeOptions(options)
      }),
    download: (url: string, options?: FetchOptions, fileName: string) => executeDownload(url, makeOptions(options), fileName)

  }
}
